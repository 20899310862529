import React from "react";
import {
  Theme,
  Banner,
  Header,
  Footer,
  Lightbox,
  ContactModal,
  MobileMenu,
} from "@ottomotors/ottomotors.com/components";
import { NoJs } from "@ottomotors/ottomotors-common/components";
import "modern-normalize/modern-normalize.css";
import "@ottomotors/ottomotors-common/styles/global.css";

import * as styles from "./styles.module.scss";

interface IProps {
  children: React.ReactNode;
  className?: string;
}

const Layout = ({ children }: IProps) => {
  return (
    <>
      <NoJs />
      <Theme />
      <Lightbox />
      <ContactModal />
      <MobileMenu />
      <Banner />
      <Header />
      <main className={styles.container}>{children}</main>
      <Footer />
    </>
  );
};

export default Layout;
