import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "@reach/router";
import cn from "classnames";
import {
  Button,
  IntersectionAnimation,
  LayoutProvider,
  SVG,
} from "@ottomotors/ottomotors-common/components";
import {
  FilterDropdown,
  SliceConfig,
} from "@ottomotors/ottomotors.com/components";
import { IOttoMotorsArticleFilter } from "@ottomotors/ottomotors-sanity";
import ResourceCard from "@ottomotors/ottomotors.com/components/CardCarousel/components/ResourceCard";
import { useResourceArticles } from "@ottomotors/ottomotors.com/hooks";
import { useBreakpoints } from "@ottomotors/ottomotors-common/hooks";

import MobileFilter from "./components/MobileFilter";

import * as styles from "./styles.module.scss";

interface IProps {
  data: IOttoMotorsArticleFilter;
}

const TOPIC_FILTERS = [
  {
    label: `AMRs`,
    value: `amrs`,
  },
  {
    label: `Applications`,
    value: `applications`,
  },
  {
    label: `Getting Started`,
    value: `gettingStarted`,
  },
  {
    label: `Industry Trends`,
    value: `industryTrends`,
  },
  {
    label: `Operational Benefits`,
    value: `operationalBenefits`,
  },
  {
    label: `Services`,
    value: `services`,
  },
  {
    label: `Software`,
    value: `software`,
  },
];

const TYPE_FILTERS = [
  {
    label: `Blog`,
    value: `blogArticle`,
  },
  {
    label: `Case Study`,
    value: `caseStudy`,
  },
  {
    label: `Download`,
    value: `download`,
  },
  {
    label: `Ebook`,
    value: `ebook`,
  },
  {
    label: `Video`,
    value: `video`,
  },
  {
    label: `Webinar`,
    value: `webinar`,
  },
  {
    label: `White Paper`,
    value: `whitePaper`,
  },
];

const ArticleFilter = ({ data }: IProps) => {
  // ---------------------------------------------------------------------------
  // variables

  const { heading, sliceConfig } = data || {};
  const { tablet, largeTablet } = useBreakpoints();

  const location = useLocation();
  const allArticles = useResourceArticles();

  // ---------------------------------------------------------------------------
  // ref / state

  const articleFiltersRef = useRef();

  const [isLoaded, setIsLoaded] = useState(false);
  const [typeFilters, setTypeFilters] = useState<string[]>([]);
  const [topicFilters, setTopicFilters] = useState<string[]>([]);
  const [sort, setSort] = useState(`publishedDate`);
  const [currentPage, setCurrentPage] = useState(1);
  const [isMobileFilterOpen, setIsMobileFilterOpen] = useState(true);
  const [articlesPerPage, setArticlesPerPage] = useState(largeTablet ? 12 : 6);

  const [visibleArticles, setVisibleArticles] = useState(
    allArticles.slice(0, articlesPerPage)
  );

  // ---------------------------------------------------------------------------
  // methods

  // Add a function to parse URL and extract type from it
  const getTypeFromURL = () => {
    if (typeof window === `undefined`) {
      return;
    }

    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get("type") || "";
  };

  const getFilteredArticles = () => {
    let filteredArticles = JSON.parse(JSON.stringify(allArticles));

    if (typeFilters.length > 0) {
      filteredArticles = filteredArticles.filter((article) => {
        return typeFilters.some((filterValue) => {
          switch (filterValue) {
            case `blogArticle`:
              return article?._type === `ottomotors.blogArticle`;
            case `caseStudy`:
              return article?._type === `ottomotors.caseStudy`;
            case `webinar`:
              return article?._type === `ottomotors.webinar`;
            case `whitePaper`:
              return article?._type === `ottomotors.whitePaper`;
            case `video`:
              return article?._type === `ottomotors.video`;
            case `ebook`:
              return article?._type === `ottomotors.ebook`;
            case `download`:
              return article?._type === `ottomotors.download`;
            default:
              return false;
          }
        });
      });
    }

    if (topicFilters.length > 0) {
      filteredArticles = filteredArticles.filter((article) =>
        topicFilters.some((filterValue) =>
          article.resourceTopics?.topics.includes(filterValue)
        )
      );
    }

    switch (sort) {
      case `alphabetical`:
        filteredArticles.sort((a, b) => {
          if (a.title < b.title) {
            return -1;
          }

          if (a.title > b.title) {
            return 1;
          }

          return 0;
        });

        break;

      case `publishedDate`:
        filteredArticles.sort((a, b) => {
          if (a.publishedDate < b.publishedDate) {
            return 1;
          }

          if (a.publishedDate > b.publishedDate) {
            return -1;
          }

          return 0;
        });

        break;

      default:
        break;
    }

    return filteredArticles;
  };

  const paginate = () => {
    const nextPage = currentPage + 1;
    const startIndex = currentPage * articlesPerPage;
    const endIndex = nextPage * articlesPerPage;

    const newVisibleArticles = getFilteredArticles().slice(
      startIndex,
      endIndex
    );

    setVisibleArticles([...visibleArticles, ...newVisibleArticles]);
    setCurrentPage(nextPage);
  };

  const handleClearFilter = () => {
    setTopicFilters([]);
    setTypeFilters([]);
  };

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    const urlType = getTypeFromURL();

    // edge case for when the user is already on the /resource page and selects a resource type from the header nav
    // if a different resource type is selected, force the component to reload and set new type filters
    // by toggling the state of "isLoaded" we force the FilterDropdown component to re-render with an updated cache of selected filters.
    const reload = urlType && !typeFilters.includes(urlType);

    if (reload) {
      setIsLoaded(false);
    }

    if (isLoaded || !articleFiltersRef?.current) {
      return;
    }

    setTypeFilters(urlType ? [urlType] : []);

    if (articleFiltersRef.current && urlType) {
      articleFiltersRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }

    setIsLoaded(true);
  }, [isLoaded, location, articleFiltersRef]);

  useEffect(() => {
    setCurrentPage(1);

    const filteredArticles = getFilteredArticles();

    setVisibleArticles(filteredArticles.slice(0, articlesPerPage));
  }, [topicFilters, typeFilters, sort, articlesPerPage]);

  useEffect(() => {
    const shownArticles = largeTablet ? 12 : 6;
    setArticlesPerPage(shownArticles);
  }, [largeTablet]);

  return (
    <SliceConfig config={sliceConfig}>
      <section ref={articleFiltersRef} className={styles.container}>
        <LayoutProvider grid>
          <h2 className={cn("h2", styles.heading)}>
            <span className={styles.headingText}>{heading}</span>
          </h2>

          <div className={styles.filterBar}>
            <div className={styles.filterDropdowns}>
              {isLoaded && tablet && (
                <FilterDropdown
                  label="Topic"
                  filters={TOPIC_FILTERS}
                  activeFilters={topicFilters}
                  setFilters={setTopicFilters}
                />
              )}

              {isLoaded && tablet && (
                <FilterDropdown
                  label="Content Type"
                  filters={TYPE_FILTERS}
                  activeFilters={typeFilters}
                  setFilters={setTypeFilters}
                />
              )}
            </div>

            {(topicFilters?.[0] || typeFilters?.[0]) && (
              <button
                type="button"
                className={styles.clearButton}
                onClick={() => handleClearFilter()}
              >
                <span className="button">Clear All</span>
                <figure className={styles.clearButtonSvg}>
                  <SVG svg="x" />
                </figure>
              </button>
            )}
          </div>

          {/* mobile filter button */}
          <div className={styles.filterButtonsMobile}>
            <Button
              text="Filter"
              iconRight="plus"
              variant="secondary"
              onClick={() => setIsMobileFilterOpen(true)}
            />

            <button
              type="button"
              className={styles.clearButton}
              onClick={() => handleClearFilter()}
            >
              <span className="button">Clear All</span>
              <figure className={styles.clearButtonSvg}>
                <SVG svg="x" />
              </figure>
            </button>
          </div>
        </LayoutProvider>

        <LayoutProvider className={styles.articles} grid>
          {visibleArticles.map((article) => {
            return (
              <ResourceCard
                key={`article-filter-card-${article?._id}`}
                className={styles.article}
                data={article}
              />
            );
          })}

          <footer className={styles.footer}>
            <>
              <p className="caption">
                Currently displaying: {visibleArticles?.length} /{" "}
                {getFilteredArticles()?.length} results
              </p>
              {visibleArticles?.[0] &&
                visibleArticles?.length !== getFilteredArticles()?.length && (
                  <IntersectionAnimation>
                    <Button
                      text="Load More"
                      variant="secondary"
                      iconRight="arrowRight"
                      iconLeft="arrowRight"
                      animateOnHover
                      onClick={() => {
                        paginate();
                      }}
                    />
                  </IntersectionAnimation>
                )}
            </>
          </footer>
        </LayoutProvider>

        {/* todo: hide this on desktop so it remounts on mobile switch */}
        {!tablet && (
          <MobileFilter
            className={styles.mobileFilter}
            isOpen={isMobileFilterOpen}
            setIsOpen={setIsMobileFilterOpen}
            activeTypes={typeFilters}
            setActiveTypes={setTypeFilters}
            activeTopics={topicFilters}
            setActiveTopics={setTopicFilters}
            filters={[
              { type: "topic", heading: "Topic", filters: TOPIC_FILTERS },
              { type: "type", heading: "Content Type", filters: TYPE_FILTERS },
            ]}
          />
        )}
      </section>
    </SliceConfig>
  );
};

export default ArticleFilter;
