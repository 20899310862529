import React from "react";
import cn from "classnames";
import { LayoutProvider, Tag } from "@ottomotors/ottomotors-common/components";
import FooterItem from "./components/FooterItem";
import {
  SliceConfig,
  VariableMedia,
} from "@ottomotors/ottomotors.com/components";
import { IOttoMotorsArticleAtf } from "@ottomotors/ottomotors-sanity";
import { IPageMetadata } from "@ottomotors/ottomotors.com/components/SanitySlice";
import dayjs from "dayjs";

import * as styles from "./styles.module.scss";

interface IProps {
  data: IOttoMotorsArticleAtf;
  pageMetadata?: IPageMetadata;
}

const ArticleAtf = ({ data, pageMetadata }: IProps) => {
  const { media, type, heading, author, readTime, watchTime, sliceConfig } =
    data || {};

  const duration = watchTime || readTime;
  let publishedDate;

  if (pageMetadata?.publishedDate) {
    publishedDate = dayjs(pageMetadata.publishedDate).format("MMMM D, YYYY");
  }

  const getFormattedTag = (tag) => {
    if (!tag) return;

    let formattedTag;

    switch (tag) {
      case "amrs":
        formattedTag = "AMRs";
        break;
      case "software":
        formattedTag = "Software";
        break;
      case "operationalBenefits":
        formattedTag = "Operational Benefits";
        break;
      case "applications":
        formattedTag = "Applications";
        break;
      case "gettingStarted":
        formattedTag = "Getting Started";
        break;
      case "industryTrends":
        formattedTag = "Industry Trends";
        break;
      case "services":
        formattedTag = "Services";
        break;
      default:
        break;
    }

    return formattedTag;
  };

  return (
    <SliceConfig config={sliceConfig}>
      <section className={styles.container}>
        <LayoutProvider grid>
          <header className={styles.header}>
            {type && (
              <p className="label">{type === "Blog Article" ? "Blog" : type}</p>
            )}
            {heading && <h1 className="d1">{heading}</h1>}
          </header>

          <div className={styles.footer}>
            <LayoutProvider className={styles.footerGrid} grid fullWidthGrid>
              {publishedDate && (
                <FooterItem
                  className={styles.footerItem}
                  label="Published"
                  body={publishedDate}
                />
              )}

              {author && (
                <FooterItem
                  className={styles.footerItem}
                  label="Author"
                  body={author}
                />
              )}

              {duration && (
                <FooterItem
                  className={styles.footerItem}
                  label={watchTime ? "Watch Time" : "Read Time"}
                  body={`${duration} min${duration > 1 ? `s` : ``}`}
                />
              )}

              {pageMetadata?.topics?.[0] && (
                <ul className={cn(styles.footerItem, styles.tags)}>
                  {pageMetadata.topics.map((tag, index) => {
                    return (
                      <li key={`article-atf-tag-${index}-${tag}`}>
                        <Tag text={getFormattedTag(tag)} variant="outline" />
                      </li>
                    );
                  })}
                </ul>
              )}

              {/* <ShareButtons
                className={styles.share}
                heading={heading}
                description={description}
              /> */}
            </LayoutProvider>
          </div>

          {(media?.image || media?.video) && (
            <VariableMedia
              className={cn(styles.media, {
                [styles.videoContainer]: media?.video,
              })}
              media={media}
              controls
            />
          )}
        </LayoutProvider>
      </section>
    </SliceConfig>
  );
};

export default ArticleAtf;
