import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import { LayoutProvider, SVG } from "@ottomotors/ottomotors-common/components";
import {
  useIframeResizer,
  useSiteSettings,
} from "@ottomotors/ottomotors.com/hooks";
import { TextLockup } from "@ottomotors/ottomotors.com/components";
import { useApp } from "@ottomotors/ottomotors.com/hooks";

import * as styles from "./styles.module.scss";

const ContactModal = () => {
  const {
    isContactModalOpen,
    setIsContactModalOpen,
    contactModalEmbedOverride,
  } = useApp();

  const siteSettings = useSiteSettings();

  const iframeRef = useRef();
  const [iframeWidth, setIframeWidth] = useState(0);

  useEffect(() => {
    if (!iframeRef?.current) {
      return;
    }

    const updateIframeWidth = () => {
      const { width } = iframeRef.current.getBoundingClientRect();
      setIframeWidth(width);
    };

    updateIframeWidth();
    window.addEventListener("resize", updateIframeWidth);

    return () => {
      window.removeEventListener("resize", updateIframeWidth);
    };
  }, [iframeRef]); // Empty dependency array means this effect runs once on mount

  const { contactOverlay } = siteSettings;

  // default to siteSettings embed
  let embed = contactOverlay.embed;
  let embedHeightDesktop = contactOverlay.embedHeightDesktop;
  let embedHeightMobile = contactOverlay.embedHeightMobile;

  if (
    contactModalEmbedOverride?.embed &&
    contactModalEmbedOverride?.embedHeightDesktop &&
    contactModalEmbedOverride?.embedHeightMobile
  ) {
    embed = contactModalEmbedOverride.embed;
    embedHeightDesktop = contactModalEmbedOverride.embedHeightDesktop;
    embedHeightMobile = contactModalEmbedOverride.embedHeightMobile;
  }

  const { parsedEmbed } = useIframeResizer(
    iframeWidth,
    embed,
    embedHeightDesktop,
    embedHeightMobile
  );

  return (
    <div
      className={cn(styles.container, { [styles.visible]: isContactModalOpen })}
    >
      <LayoutProvider className={styles.grid} grid>
        <div className={cn(styles.form)}>
          <div className={styles.close}>
            <button type="button" onClick={() => setIsContactModalOpen(false)}>
              <figure className={styles.closeSvg}>
                <SVG svg="x" />
              </figure>
            </button>
          </div>

          <TextLockup
            data={
              contactModalEmbedOverride?.textLockup || contactOverlay.textLockup
            }
          />

          <div className={styles.iframe}>
            <div
              ref={iframeRef}
              dangerouslySetInnerHTML={{ __html: parsedEmbed }}
            />
          </div>
        </div>
      </LayoutProvider>
    </div>
  );
};

export default ContactModal;
