import React, { useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import cn from "classnames";
import { GatsbyImage } from "gatsby-plugin-image";
import { SVG } from "@ottomotors/ottomotors-common/components";
import { IAltImage } from "@ottomotors/ottomotors-sanity";

import * as styles from "./styles.module.scss";

interface IProps {
  src: string;
  thumbnail?: IAltImage;
  className?: string;
  controls?: boolean;
}

const VideoPlay = ({ src, thumbnail, className, controls = false }: IProps) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [hasPlayed, setHasPlayed] = useState(false);
  const [inViewRef, inView] = useInView();

  const handlePlayButtonClick = () => {
    if (isPlaying) return;

    if (videoRef.current) {
      videoRef.current.play();
      setIsPlaying(true);
      setHasPlayed(true);
    }
  };

  // when the video element is clicked
  const handleVideoClick = () => {
    if (!videoRef?.current) return;

    if (isPlaying) {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  useEffect(() => {
    if (!videoRef?.current) return;

    if (!inView && isPlaying) {
      videoRef.current.currentTime = 0; // Reset video to the beginning
      videoRef.current.pause();
      setIsPlaying(false);
    }
  }, [inView, isPlaying]);

  // add an event listener to show and hide custom play buttom when the video is paused with the native controls
  useEffect(() => {
    if (!controls) return;

    const handlePause = (event: any) => {
      if (videoRef.current && videoRef.current.seeking) {
        return; // Bail out if video is seeking
      }
      setIsPlaying(false);
    };

    if (videoRef.current) {
      videoRef.current.addEventListener("pause", handlePause);
    }

    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener("pause", handlePause);
      }
    };
  }, [videoRef.current]);

  return (
    <div ref={inViewRef} className={cn(styles.container, className)}>
      <video
        ref={videoRef}
        className={cn(styles.video, { [styles.playing]: isPlaying })}
        onClick={() => handleVideoClick()}
        loop={!controls}
        playsInline={!controls}
        controls={controls && isPlaying}
      >
        <source src={src} type="video/mp4" />
      </video>

      {(thumbnail?.asset?.gatsbyImageData || thumbnail?.asset?.url) && (
        <figure className={cn(styles.thumbnail, { [styles.hide]: hasPlayed })}>
          {thumbnail?.asset?.gatsbyImageData ? (
            <GatsbyImage
              image={thumbnail.asset.gatsbyImageData}
              alt={thumbnail.altText || "OTTO Motors"}
            />
          ) : (
            <img src={thumbnail.asset.url} alt={thumbnail.altText || "OTTO Motors"} />
          )}
        </figure>
      )}

      <button
        className={cn(styles.playButton, { [styles.hidden]: isPlaying })}
        type="button"
        onClick={() => handlePlayButtonClick()}
      >
        <figure className={styles.playButtonContent}>
          <SVG className={styles.circleSvg} svg="circleBlurred"></SVG>
          <SVG className={styles.playSvg} svg="play" />
          <p className={cn("button", styles.playText)}>Play</p>
        </figure>
      </button>
    </div>
  );
};

export default VideoPlay;
