import React from "react";
import cn from "classnames";
import { UniversalLink } from "@ottomotors/ottomotors-common/components";
import { IOttoMotorsTextLockup } from "@ottomotors/ottomotors-sanity";

import * as styles from "./styles.module.scss";

interface IProps {
  forwardRef?: React.ForwardedRef<HTMLDivElement>;
  data: IOttoMotorsTextLockup;
  buttonFlexDirection?: "column" | "row";
  className?: string;
  inView?: boolean;
  fullWidth?: boolean;
}

const TextLockup = ({
  forwardRef,
  data,
  buttonFlexDirection = "column",
  fullWidth = false,
  className,
}: IProps) => {
  const {
    body,
    bodyTypestyle,
    caption,
    captionTypestyle,
    heading,
    isPageHeading,
    headingTypestyle,
    subheading,
    buttonPrimary,
    buttonSecondary,
    theme,
  } = data || {};

  const { theme: colorTheme } = theme || {};

  const isLightTheme = colorTheme === `light`;
  const HeadingElement = isPageHeading ? "h1" : "h2";
  let hasButtonPrimary;
  let hasButtonSecondary;

  if (Array.isArray(buttonPrimary?.link)) {
    hasButtonPrimary =
      buttonPrimary?.text && (buttonPrimary?.link[0] || buttonPrimary?.video);
  }

  if (Array.isArray(buttonSecondary?.link)) {
    hasButtonSecondary =
      buttonSecondary?.text &&
      (buttonSecondary?.link[0] || buttonSecondary?.video);
  }

  if (
    !body &&
    !caption &&
    !heading &&
    !subheading &&
    !hasButtonPrimary &&
    !hasButtonSecondary
  ) {
    return null;
  }

  return (
    <div
      ref={forwardRef}
      className={cn(styles.container, className, {
        [styles.light]: isLightTheme,
      })}
    >
      <div className={styles.content}>
        <article className={styles.text}>
          {subheading && (
            <p
              className={cn("label", styles.subheading, {
                [styles.fullWidth]: fullWidth,
              })}
            >
              {subheading}
            </p>
          )}
          {heading && (
            <HeadingElement
              className={cn(headingTypestyle || "h2", styles.heading, {
                [styles.fullWidth]: fullWidth,
              })}
            >
              {heading}
            </HeadingElement>
          )}
          {body && (
            <p
              className={cn(bodyTypestyle || "h4", styles.body, {
                [styles.fullWidth]: fullWidth,
              })}
            >
              {body}
            </p>
          )}
          {caption && (
            <p
              className={cn(captionTypestyle || "b1", styles.caption, {
                [styles.fullWidth]: fullWidth,
              })}
            >
              {caption}
            </p>
          )}
        </article>

        {(hasButtonPrimary || hasButtonSecondary) && (
          <div className={cn(styles.buttons)}>
            {hasButtonPrimary && (
              <div
                className={cn(styles.buttonContainer, {
                  // [styles.twoCol]: hasButtonPrimary && hasButtonSecondary,
                })}
              >
                <UniversalLink
                  link={buttonPrimary}
                  iconRight="arrowRight"
                  iconLeft="arrowRight"
                  animateOnHover
                  theme={isLightTheme ? "lightOnDark" : "darkOnLight"}
                />
              </div>
            )}

            {hasButtonSecondary && (
              <div
                className={cn(styles.buttonContainer, {
                  // [styles.twoCol]: hasButtonPrimary && hasButtonSecondary,
                })}
              >
                <UniversalLink
                  link={buttonSecondary}
                  iconRight="arrowRight"
                  iconLeft="arrowRight"
                  animateOnHover
                  theme={isLightTheme ? "lightOnDark" : "darkOnLight"}
                  variant="secondary"
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default TextLockup;
