import React, { useEffect } from "react";
import Cookies from "js-cookie";
import { useApp, useSiteSettings } from "@ottomotors/ottomotors.com/hooks";
import { SVG, UniversalLink } from "@ottomotors/ottomotors-common/components";
import cn from "classnames";

import * as styles from "./styles.module.scss";

const Banner = () => {
  const siteSettings = useSiteSettings();
  const { isBannerVisible, setIsBannerVisible } = useApp();
  const banner = siteSettings?.banner;
  
  useEffect(() => {
    const hideBannerCookie = Cookies.get("ottomotors.hideBanner");
    if (!banner?.showBanner && hideBannerCookie) {
      Cookies.remove("ottomotors.hideBanner");
    } else if (!hideBannerCookie && !isBannerVisible && banner?.showBanner) {
      setIsBannerVisible(true);
    }
  }, []);

  const hideBanner = () => {
    setIsBannerVisible(false);
  }

  if (!banner?.showBanner) return null;

  return (
    <div className={cn(styles.container, { [styles.hide]: !isBannerVisible })}>
      <p className="b2">{banner?.text}</p>
      {banner?.link?.link?.[0] && (
        <UniversalLink
          link={banner?.link}
          iconRight="arrowRight"
          iconLeft="arrowRight"
          animateOnHover
          theme={"darkOnLight"}
          variant={null}
          className={styles.link}
        />
      )}
      <button
        className={styles.closeButton}
        type="button"
        onClick={() => hideBanner()}
      >
        <SVG svg="x" />
      </button>
    </div>
  );
};

export default Banner;
