// extracted by mini-css-extract-plugin
export var accordion = "styles-module--accordion--106c8";
export var accordionContent = "styles-module--accordionContent--38ac5";
export var accordionTitle = "styles-module--accordionTitle--3f832";
export var active = "styles-module--active--0236f";
export var chevron = "styles-module--chevron--ea5a4";
export var container = "styles-module--container--c4bf5";
export var desktop = "1340px";
export var fixedContainer = "styles-module--fixedContainer--7fe56";
export var giant = "2200px";
export var hidden = "styles-module--hidden--f0de0";
export var isExpanded = "styles-module--isExpanded--c046c";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var link = "styles-module--link--4cb8f";
export var links = "styles-module--links--39eff";
export var mobile = "400px";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "768px";
export var title = "styles-module--title--09b04";