import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import { SVG } from "@ottomotors/ottomotors-common/components";
import { useKeyPress } from "@ottomotors/ottomotors-common/hooks";

import * as styles from "./styles.module.scss";

interface IFilter {
  label: string;
  value: string;
  width: number;
}

interface IProps {
  label: string;
  filters: IFilter[];
  activeFilters: any;
  setFilters?: any;
  urlParam?: string;
}

const FilterDropdown = ({
  label,
  filters,
  activeFilters,
  setFilters = {},
  urlParam,
}: IProps) => {
  // ---------------------------------------------------------------------------
  // variables

  const escKeyPressed = useKeyPress("Escape");

  // ---------------------------------------------------------------------------
  // ref / state

  const ref = useRef(null);
  const filtersRef = useRef([]);
  const badgeContainerRef = useRef(null);
  const cachedFiltersRef = useRef(activeFilters); // cache of the parents currently selected filters

  const [isLoaded, setIsLoaded] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [containerWidth, setContainerWidth] = useState(null);
  const [parsedFilters, setParsedFilters] = useState<IFilter[]>([]); // array of filter objects with a width property
  const [selectedFilters, setSelectedFilters] = useState(filters);
  const [isOverflowing, setIsOverflowing] = useState(false);

  // ---------------------------------------------------------------------------
  // methods

  const handleCheckboxChange = (index: number) => {
    const selectedFilter = parsedFilters[index];

    if (selectedFilters.includes(selectedFilter)) {
      setSelectedFilters((prevState) => {
        const updatedFilters = prevState.filter(
          (filter) => filter.value !== selectedFilter.value
        );

        return updatedFilters;
      });

      return;
    }

    setSelectedFilters((prevState) => [...prevState, selectedFilter]);
  };

  const handleRemoveFilter = (e: React.SyntheticEvent, filter: IFilter) => {
    e.stopPropagation();

    const updatedFilters = selectedFilters.filter(
      (selectedFilter) => selectedFilter.value !== filter.value
    );

    setSelectedFilters(updatedFilters);
  };

  const handleRemoveAllFilters = (e: React.SyntheticEvent) => {
    e.stopPropagation();

    setSelectedFilters([]);
  };

  // ---------------------------------------------------------------------------
  // lifecycle

  //
  useEffect(() => {
    // update parent filters when selectedFilters is changed
    const updatedFilters = selectedFilters.map(({ value }) => value);

    setFilters(updatedFilters);
  }, [selectedFilters]);

  useEffect(() => {
    // setup for filter tags
    // get width of all filter tabs and store it inside a new state array, parsedFilters
    if (!filtersRef?.current?.[0]) {
      return;
    }

    const allFilters: IFilter[] = [];

    filtersRef.current.forEach((filterRef, index) => {
      const boundingRect = filterRef?.getBoundingClientRect();

      allFilters.push({
        ...filters[index],
        width: parseInt(boundingRect.width) + 1,
      });
    });

    setParsedFilters(allFilters);
  }, [filtersRef]);

  useEffect(() => {
    if (!isLoaded && parsedFilters?.[0]) {
      if (!cachedFiltersRef?.current?.[0]) {
        setSelectedFilters([]);
      } else {
        const alreadySelectedFilters = cachedFiltersRef.current.map(
          (filterName: string) =>
            parsedFilters.find(({ value }) => value === filterName)
        );

        setSelectedFilters(alreadySelectedFilters);
      }

      setIsLoaded(true);
    }
  }, [isLoaded, cachedFiltersRef, parsedFilters]);

  useEffect(() => {
    // handle instances when a "Clear All" button has been pressed from the parent or sibling components
    if (isLoaded && !activeFilters?.[0] && selectedFilters?.[0]) {
      setSelectedFilters([]);
    }
  }, [isLoaded, activeFilters]);

  useEffect(() => {
    if (
      !badgeContainerRef?.current ||
      !selectedFilters?.[0] ||
      !containerWidth
    ) {
      return;
    }

    const aggregateWidth = selectedFilters.reduce((acc, filter) => {
      return acc + filter.width;
    }, 0);

    const gapWidth = 10; // gap between each filter in pixels with a plus 2 px more just to be safe
    const gapCount = selectedFilters.length - 1; // number of "gaps" between filters
    const totalGap = gapWidth * gapCount;
    const totalTagWidth = aggregateWidth + totalGap;

    setIsOverflowing(totalTagWidth > containerWidth);
  }, [badgeContainerRef, containerWidth, selectedFilters]);

  useEffect(() => {
    if (!badgeContainerRef?.current || !isLoaded) {
      return () => {};
    }

    const handleResize = () => {
      setContainerWidth(
        badgeContainerRef.current?.getBoundingClientRect().width
      );
    };

    window.addEventListener("resize", () => {
      handleResize();
    });

    handleResize();

    return () => {
      window.removeEventListener("resize", () => {
        handleResize();
      });
    };
  }, [isLoaded, badgeContainerRef]);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen]);

  useEffect(() => {
    if (escKeyPressed) {
      setIsOpen(false);
    }
  }, [escKeyPressed]);

  return (
    <div ref={ref} className={styles.filterSelectContainer}>
      <p className="b2">{label}</p>

      <div className={styles.filterSelect}>
        <button
          className={styles.filterSelectButton}
          type="button"
          onClick={() => setIsOpen((prevState) => !prevState)}
        >
          <div
            ref={badgeContainerRef}
            className={cn(styles.filterSelectBox, {
              [styles.loaded]: isLoaded,
            })}
          >
            {(selectedFilters?.[0] && (
              <>
                {!isOverflowing &&
                  selectedFilters.map((filter, index) => {
                    return (
                      <div
                        key={index}
                        className={cn("caption", styles.filtersSelectBoxText)}
                        ref={(el) => (filtersRef.current[index] = el)}
                        onClick={(e) => handleRemoveFilter(e, filter)}
                      >
                        <span>{filter.label}</span>
                        <figure className={styles.filterSelectBoxCross}>
                          <SVG svg="x" />
                        </figure>
                      </div>
                    );
                  })}

                {isOverflowing && (
                  <div
                    className={cn("caption", styles.filtersSelectBoxText)}
                    onClick={(e) => handleRemoveAllFilters(e)}
                  >
                    <span>{selectedFilters.length} selected</span>
                    <figure className={styles.filterSelectBoxCross}>
                      <SVG svg="x" />
                    </figure>
                  </div>
                )}
              </>
            )) || <span className="b2">Select</span>}
          </div>

          <figure
            className={cn(styles.filterSelectChevron, {
              [styles.open]: isOpen,
            })}
          >
            <SVG svg="chevronDown" />
          </figure>
        </button>

        <ul
          className={cn(styles.filterSelectOptions, {
            [styles.visible]: isOpen,
          })}
        >
          <li className={cn(styles.clearAll)}>
            <button
              className={styles.clearButton}
              type="button"
              onClick={() => setSelectedFilters([])}
            >
              <figure className={styles.filterInputCross}>
                <SVG svg="x" />
              </figure>

              <p className={cn("button")}>Clear All</p>
            </button>
          </li>

          {parsedFilters?.[0] &&
            parsedFilters.map((filter, index) => {
              return (
                <li
                  key={`article-grid-filter-${filter.value}`}
                  className={cn(styles.filterSelectItem)}
                >
                  <div className={styles.filterInputContainer}>
                    <input
                      className={styles.filterInput}
                      type="checkbox"
                      name={filter.label}
                      value={filter.value}
                      id={filter.value}
                      checked={selectedFilters?.includes(filter)}
                      onChange={() => handleCheckboxChange(index)}
                    />

                    <figure className={styles.filterInputCheck}>
                      <SVG svg="check" />
                    </figure>
                  </div>

                  <label
                    className={cn("label", styles.filterInputLabel)}
                    htmlFor={filter.value}
                  >
                    {filter.label}
                  </label>
                </li>
              );
            })}
        </ul>
      </div>
    </div>
  );
};

export default FilterDropdown;
