import React from "react";
import { LayoutProvider } from "@ottomotors/ottomotors-common/components";
import { SliceConfig, TextLockup } from "@ottomotors/ottomotors.com/components";
import { IOttoMotorsInteractiveFactory } from "@ottomotors/ottomotors-sanity";

import * as styles from "./styles.module.scss";

interface IProps {
  data: IOttoMotorsInteractiveFactory;
}

const InteractiveFactory = ({ data: { sliceConfig, textLockup } }: IProps) => {
  return (
    <SliceConfig config={sliceConfig}>
      <div className={styles.container}>
        <LayoutProvider grid>
          {textLockup && (
            <TextLockup className={styles.text} data={textLockup} />
          )}
          <div className={styles.iframeContainer}>
            <iframe
              className={styles.iframe}
              allow="fullscreen"
              src="https://oto-cpg.onrender.com/"
            ></iframe>
          </div>
        </LayoutProvider>
      </div>
    </SliceConfig>
  );
};

export default InteractiveFactory;
