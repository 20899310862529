import { useEffect, useState } from "react";

// this is a magic number that matches the template media queries in Pardot
const IFRAME_BREAKPOINT = 436;

const useIframeResizer = (
  iframeWidth,
  embed,
  embedHeightDesktop,
  embedHeightMobile
) => {
  const [iframeDimensions, setIframeDimensions] = useState({
    width: "100%",
    height: embedHeightDesktop,
  });

  const updateEmbedDimensions = (
    embedString: string,
    dimensions: { width: string; height: number }
  ) => {
    const widthRegex = /width="[^"]*"/;
    const heightRegex = /height="[^"]*"/;

    // todo: remove
    if (!embedString) return;

    let updatedEmbed = embedString;

    if (widthRegex.test(updatedEmbed)) {
      updatedEmbed = updatedEmbed.replace(
        widthRegex,
        `width="${dimensions.width}"`
      );
    } else {
      updatedEmbed = updatedEmbed.replace(
        "<iframe ",
        `<iframe width="${dimensions.width}" `
      );
    }

    if (heightRegex.test(updatedEmbed)) {
      updatedEmbed = updatedEmbed.replace(
        heightRegex,
        `height="${dimensions.height}"`
      );
    } else {
      updatedEmbed = updatedEmbed.replace(
        "<iframe ",
        `<iframe height="${dimensions.height}" `
      );
    }

    return updatedEmbed;
  };

  useEffect(() => {
    setIframeDimensions((prevDimensions) => ({
      ...prevDimensions,
      height:
        iframeWidth > IFRAME_BREAKPOINT
          ? embedHeightDesktop
          : embedHeightMobile,
    }));
  }, [iframeWidth, embedHeightDesktop, embedHeightMobile]);

  const parsedEmbed = updateEmbedDimensions(embed, iframeDimensions);

  return { iframeDimensions, parsedEmbed };
};

export default useIframeResizer;
