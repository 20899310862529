import { IPlace } from "../../ottomotors.com/src/slices/DealerMap/components/Map";

export const remToPx = (rem: number) => {
  if (typeof window === `undefined`) {
    return rem;
  }

  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
};

interface IRBG {
  red: string;
  green: string;
  blue: string;
}

interface ICMYK {
  c: string;
  m: string;
  y: string;
  k: string;
}

export const hexToRGB: (hex: string) => IRBG = (hex) => {
  const hexWithoutHash = hex.replace(/^#/, "");

  // Helper function to convert to three digits
  const toThreeDigits = (num: number) => num.toString().padStart(3, "0");

  // Convert the background color to RGB
  const red = toThreeDigits(parseInt(hexWithoutHash.substring(0, 2), 16));
  const green = toThreeDigits(parseInt(hexWithoutHash.substring(2, 4), 16));
  const blue = toThreeDigits(parseInt(hexWithoutHash.substring(4, 6), 16));

  return {
    red,
    green,
    blue,
  };
};

export const rgbToCMYK: (rgb: IRBG) => ICMYK = (rgb) => {
  const { blue, green, red } = rgb;

  const redNumber = parseInt(red);
  const greenNumber = parseInt(green);
  const blueNumber = parseInt(blue);

  // Convert to CMY
  let c = 1 - redNumber / 255;
  let m = 1 - greenNumber / 255;
  let y = 1 - blueNumber / 255;

  // Calculate the black key (K)
  const k = Math.min(c, Math.min(m, y));

  if (k < 1) {
    c = (c - k) / (1 - k);
    m = (m - k) / (1 - k);
    y = (y - k) / (1 - k);
  } else {
    c = 0;
    m = 0;
    y = 0;
  }

  // Convert decimals to percentages for final CMYK values
  c = Math.round(c * 100);
  m = Math.round(m * 100);
  y = Math.round(y * 100);
  const kPercent = Math.round(k * 100);

  const cString = c.toString();
  const mString = m.toString();
  const yString = y.toString();
  const kString = kPercent.toString();

  return { c: cString, m: mString, y: yString, k: kString };
};

export const capitalizeString = (string: string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const spliceFromStateArray = (array: any[], item: any) => {
  if (!array?.[0] || !array?.includes(item)) {
    return array;
  }

  const arrayClone = JSON.parse(JSON.stringify(array));

  arrayClone.splice(array.indexOf(item), 1);

  return arrayClone;
};

export const spliceFromStateArrayByProperty = (
  array: any[],
  key: string,
  value: any
) => {
  if (!array?.[0]) {
    return array;
  }

  const item = array?.find((arrayItem) => arrayItem?.[key] === value);

  if (!item) {
    return array;
  }

  return spliceFromStateArray(array, item);
};

export const spliceFromStateArrayByIndex = (array: any[], index: number) => {
  if (!array?.[0] || !array?.[index]) {
    return array;
  }

  const arrayClone = JSON.parse(JSON.stringify(array));

  arrayClone.splice(index, 1);

  return arrayClone;
};

export const getRandomIntByRange = (min: number, max: number) =>
  Math.floor(Math.random() * (max - min + 1) + min);

export const validateEmail = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return re.test(String(email).toLowerCase());
};

export const shuffleArray = (array: any[]) => {
  let currentIndex = array.length;
  let temporaryValue;
  let randomIndex;

  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
};

export const sortArrayByNumberProperty = (array: any[], property: string) => {
  if (!array?.[0]?.[property]) {
    return array;
  }

  return array.sort((a, b) => {
    const itemA = a?.[property] || 9999999999;
    const itemB = b?.[property] || 9999999999;

    if (itemA < itemB) {
      return -1;
    }

    if (itemA > itemB) {
      return 1;
    }

    return 0;
  });
};

export const splitCamelCase = (word: string) =>
  word.replace(/([A-Z])/g, ` $1`).replace(/^./, (str) => str.toUpperCase());

export const getCurrentLink = (pathname: string) => pathname;

export const rotisserieCamel = (camel: string) =>
  camel.replace(/([A-Z0-9])/g, `-$1`).toLowerCase();

export const camelCaseToTitleCase = (camelCase: string) => {
  const splitToWords = camelCase.replace(/([A-Z])/g, ` $1`);
  const capitalise =
    splitToWords.charAt(0).toUpperCase() + splitToWords.slice(1);
  return capitalise;
};
export const dashToCamel = (dashStr: string) =>
  dashStr.replace(/-([a-z])/g, (g) => g[1].toUpperCase());

export const fileToBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const result = reader.result as string;
      resolve(result);
    };
    reader.onerror = () => {
      reject(new Error("Error reading the file"));
    };
    reader.readAsDataURL(file);
  });
};

export const pxStringToNumber = (pxString: string) => {
  const stringWithoutPx = pxString.replace("px", "");
  const number = parseInt(stringWithoutPx);
  return number;
};

export const stringToCamelCase = (string: string) => {
  const words = string.split(" ");

  const camelCaseWords = words.map((word, i) => {
    if (i === 0) {
      return word.toLowerCase();
    }
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });

  return camelCaseWords.join("");
};

export const bytesToMB: (bytes: number) => number = (bytes) => {
  return parseFloat((bytes / (1024 * 1024)).toFixed(2));
};

export const distanceInKmBetweenEarthCoordinates = (
  location1Lat: number,
  location1Lng: number,
  location2Lat: number,
  location2Lng: number
) => {
  const EARTH_RADIUS_KM = 6371;

  const degreesToRadians = (degrees: number) => {
    return (degrees * Math.PI) / 180;
  };

  const deltaLat = degreesToRadians(location2Lat - location1Lat);
  const deltaLng = degreesToRadians(location2Lng - location1Lng);

  const location1LatRadians = degreesToRadians(location1Lat);
  const location2LatRadians = degreesToRadians(location2Lat);

  const halfChordLengthSquared =
    Math.sin(deltaLat / 2) * Math.sin(deltaLat / 2) +
    Math.sin(deltaLng / 2) *
      Math.sin(deltaLng / 2) *
      Math.cos(location1LatRadians) *
      Math.cos(location2LatRadians);

  const angularDistanceRadians =
    2 *
    Math.atan2(
      Math.sqrt(halfChordLengthSquared),
      Math.sqrt(1 - halfChordLengthSquared)
    );

  return EARTH_RADIUS_KM * angularDistanceRadians;
};

export const calculateDistanceBetweenGeolocations = (
  locationOne, // Latitude and longitude of the first location
  locationTwo // Latitude and longitude of the second location
) => {
  if (!locationOne || !locationTwo) return;

  const earthRadiusKm = 6371; // Radius of the Earth in kilometers

  // Convert latitude and longitude from degrees to radians
  const lat1Radians = (locationOne.lat * Math.PI) / 180;
  const lon1Radians = (locationTwo.lng * Math.PI) / 180;
  const lat2Radians = (locationTwo.lat * Math.PI) / 180;
  const lon2Radians = (locationTwo.lng * Math.PI) / 180;

  // Calculate the differences between latitudes and longitudes
  const latDiff = lat2Radians - lat1Radians;
  const lonDiff = lon2Radians - lon1Radians;

  // Calculate the Haversine formula
  const a =
    Math.sin(latDiff / 2) * Math.sin(latDiff / 2) +
    Math.cos(lat1Radians) *
      Math.cos(lat2Radians) *
      Math.sin(lonDiff / 2) *
      Math.sin(lonDiff / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  // Calculate the distance in kilometers
  const distanceKm = Math.round(earthRadiusKm * c);

  return distanceKm;
};

export const calculateCenterCoordinates = (
  places: IPlace[]
): { lat: number; lng: number } | undefined => {
  if (places.length === 0) return undefined;

  let totalLat = 0;
  let totalLng = 0;

  places.forEach((place) => {
    totalLat += place.location.lat;
    totalLng += place.location.lng;
  });

  const centerLat = totalLat / places.length;
  const centerLng = totalLng / places.length;

  return { lat: centerLat, lng: centerLng };
};

export const getUniversalLinkUrl = (universalLink) => {
  const link = universalLink?.[0];

  let url = "";

  switch (link._type) {
    case `externalUrl`:
      url = link.url;
      break;

    case `referenceToolkitPage`:
    case `referenceWebsitePage`:
    case `referenceOttomotorsPage`:
      const isIndex = link.page.slug.current === "/";

      url = isIndex
        ? `${link.page.slug.current}`
        : `/${link.page.slug.current}`;
      break;

    case `referenceOttomotorsPressRelease`:
      url = `/company/newsroom/press-releases/${link.pressRelease.slug.current}`;
      break;

    case `referenceOttomotorsResourcePage`:
      url = `/${link.resourcePage.slug.current}`;
      break;

    case `referenceOttomotorsAmr`:
      url = `/${link.amr.slug.current}`;
      break;

    case `referenceOttomotorsProduct`:
      url = `/${link.product.slug.current}`;
      break;

    case `referenceOttomotorsVideo`:
      url = `/resources/videos/${link.video.slug.current}`;
      break;

    case `referenceOttomotorsEbook`:
      url = `/resources/ebooks/${link.ebook?.slug?.current}`;
      break;

    case `referenceOttomotorsBlogArticle`:
      url = `/blog/${link.blogArticle.slug.current}`;
      break;

    case `referenceOttomotorsWebinar`:
      url = `/resources/webinars/${link.webinar.slug.current}`;
      break;

    case `referenceOttomotorsWhitePaper`:
      url = `/resources/white-papers/${link.whitePaper.slug.current}`;
      break;

    case `referenceOttomotorsCaseStudy`:
      url = `/resources/case-studies/${link.caseStudy.slug.current}`;
      break;

    case `referenceOttomotorsEvent`:
      url = `/events/${link.event.slug.current}`;
      break;

    case `referenceOttomotorsDownload`:
      url = `/resources/downloads/${link.download.slug.current}`;
      break;

    default:
      return (url = "");
  }

  return url;
};

export const getFormFields = (formType: string) => {
  if (!formType) {
    // FALLBACK
    return [
      {
        type: "text",
        name: "firstName",
        placeholder: "First Name",
        label: "First name",
        className: "split-left",
        defaultValue: "",
        validation: { required: true },
        validationMessage: "Please enter your first name",
      },
      {
        type: "text",
        name: "lastName",
        placeholder: "Last Name",
        label: "Last name",
        className: "split-right",
        defaultValue: "",
        validation: { required: true },
        validationMessage: "Please enter your last name",
      },
      {
        type: "tel",
        name: "phone",
        placeholder: "04## ### ###",
        label: "Best Phone number",
        defaultValue: "",
        validation: { required: true, minLength: 8 },
        validationMessage: "Please enter a valid number",
      },
      {
        type: "email",
        name: "email",
        placeholder: "email@domain.com",
        label: "Email address",
        defaultValue: "",
        validation: {
          required: true,
          pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        },
        validationMessage: "Please enter a valid email",
      },
      {
        type: "textarea",
        name: "message",
        placeholder: "Enter text",
        label: "Enter a message",
        defaultValue: "",
      },
    ];
  }

  if (formType.toLowerCase() === "contact") {
    // name property to matches boostr field name

    return [
      {
        type: "text",
        name: "first_name",
        placeholder: "First Name",
        label: "* First Name",
        className: "split-left",
        defaultValue: "",
        validation: { required: true },
        validationMessage: "Please enter your first name",
      },
      {
        type: "text",
        name: "last_name",
        placeholder: "Last Name",
        label: "* Last Name",
        className: "split-right",
        defaultValue: "",
        validation: { required: true },
        validationMessage: "Please enter your last name",
      },
      {
        type: "email",
        name: "email",
        placeholder: "Your email",
        label: "* Email",
        className: "split-left",
        defaultValue: "",
        validation: {
          required: true,
          pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
        },
        validationMessage: "Please enter a valid email address",
      },
      {
        type: "tel",
        name: "phone_number",
        placeholder: "Your phone number",
        label: "* Phone Number",
        className: "split-right",
        defaultValue: "",
        validation: { required: true },
        validationMessage: "Please enter your phone number",
      },
      {
        type: "text",
        name: "job_title",
        placeholder: "Job Title",
        label: "* Job Title",
        className: "split-left",
        defaultValue: "",
        validation: {
          required: true,
        },
        validationMessage: "Please enter your job title",
      },
      {
        type: "text",
        name: "company_name",
        placeholder: "Company",
        label: "* Company",
        className: "split-right",
        defaultValue: "",
        validation: {
          required: true,
        },
        validationMessage: "Please enter your company",
      },
      {
        type: "select",
        name: "country",
        placeholder: "Country",
        label: "* Country",
        className: "split-left",
        defaultValue: "",
        validation: {
          required: true,
        },
        options: ["USA", "Australia"],
        validationMessage: "Please select your country",
      },
      {
        type: "textarea",
        label: "Message",
        name: "message",
        placeholder: "Message",
        defaultValue: "",
      },
    ];
  }
};
