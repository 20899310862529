import React, { useEffect, useRef, useState } from "react";
import { LayoutProvider } from "@ottomotors/ottomotors-common/components";
import { useIframeResizer } from "@ottomotors/ottomotors.com/hooks";
import { SliceConfig } from "@ottomotors/ottomotors.com/components";
import { SVG } from "@ottomotors/ottomotors-common/components";
import { TextLockup } from "@ottomotors/ottomotors.com/components";

import { IOttoMotorsPardotEmbed } from "@ottomotors/ottomotors-sanity";
import cn from "classnames";

import * as styles from "./styles.module.scss";

interface IProps {
  data: IOttoMotorsPardotEmbed;
}

const PardotEmbed = ({
  data: {
    _key,
    sliceConfig,
    textLockup,
    links,
    embed,
    embedHeightDesktop,
    embedHeightMobile,
  },
}: IProps) => {
  const iframeRef = useRef();
  const [iframeWidth, setIframeWidth] = useState(0);

  useEffect(() => {
    if (!iframeRef?.current) {
      return;
    }

    const updateIframeWidth = () => {
      const { width } = iframeRef.current.getBoundingClientRect();
      setIframeWidth(width);
    };

    updateIframeWidth();
    window.addEventListener("resize", updateIframeWidth);

    return () => {
      window.removeEventListener("resize", updateIframeWidth);
    };
  }, [iframeRef]); // Empty dependency array means this effect runs once on mount

  const { parsedEmbed } = useIframeResizer(
    iframeWidth,
    embed,
    embedHeightDesktop,
    embedHeightMobile
  );

  return (
    <SliceConfig config={sliceConfig}>
      <section className={styles.container}>
        <LayoutProvider grid>
          <div className={cn(styles.textLockup)}>
            <TextLockup data={textLockup} />

            <ul className={cn(styles.iconLinks)}>
              {links?.[0] &&
                links.map((link, index) => {
                  const { actionType, actionValue, primary, text } = link;
                  const lines = text.split("\n");

                  const heading = lines?.[0] || "";
                  const body = lines?.[1] || "";

                  let prefix = ``;
                  let svg = `settings`;

                  if (actionType === "email") {
                    prefix = `mailto:`;
                    svg = `mail`;
                  } else if (actionType === "phone") {
                    prefix = `tel:`;
                    svg = `phone`;
                  }

                  return (
                    <li
                      key={`${_key}-iconlink-${actionType}-${actionValue}-${index}`}
                      className={styles.iconLink}
                    >
                      <a
                        href={`${prefix}${actionValue}`}
                        className={cn(styles.iconLinkFlex, {
                          [styles.primary]: primary,
                        })}
                      >
                        <p className={cn("b2", styles.iconLinkContent)}>
                          {heading && <span>{heading}</span>}
                          {body && (
                            <span className={styles.iconLinkBody}>{body}</span>
                          )}
                        </p>
                        <figure>
                          <SVG svg={svg} />
                        </figure>
                      </a>
                    </li>
                  );
                })}
            </ul>
          </div>

          <div
            ref={iframeRef}
            className={styles.iframe}
            dangerouslySetInnerHTML={{ __html: parsedEmbed }}
          />
        </LayoutProvider>
      </section>
    </SliceConfig>
  );
};

export default PardotEmbed;
