import React from "react";

import { ReactComponent as amrLifter } from "./svgs/amr-lifter.svg";
import { ReactComponent as amrSmall } from "./svgs/amr-small.svg";
import { ReactComponent as arrowDown } from "./svgs/arrow-down.svg";
import { ReactComponent as arrowLeft } from "./svgs/arrow-left.svg";
import { ReactComponent as arrowRight } from "./svgs/arrow-right.svg";
import { ReactComponent as arrowUpRight } from "./svgs/arrow-up-right.svg";
import { ReactComponent as arrowUpCircle } from "./svgs/arrow-up-circle.svg";
import { ReactComponent as download } from "./svgs/download.svg";
import { ReactComponent as ellipsis } from "./svgs/ellipsis.svg";
import { ReactComponent as settings } from "./svgs/settings.svg";
import { ReactComponent as lookingGlass } from "./svgs/looking-glass.svg";
import { ReactComponent as minus } from "./svgs/minus.svg";
import { ReactComponent as palopayWordmark } from "./svgs/palopay-wordmark.svg";
import { ReactComponent as phone } from "./svgs/phone.svg";
import { ReactComponent as mail } from "./svgs/mail.svg";
import { ReactComponent as pin } from "./svgs/pin.svg";
import { ReactComponent as playCircle } from "./svgs/play-circle.svg";
import { ReactComponent as plus } from "./svgs/plus.svg";
import { ReactComponent as radioWaves } from "./svgs/radio-waves.svg";
import { ReactComponent as rockwellOttoLogo } from "./svgs/rockwell-otto-logo.svg";
import { ReactComponent as warningTriangle } from "./svgs/warning-triangle.svg";
import { ReactComponent as weight } from "./svgs/weight.svg";
import { ReactComponent as x } from "./svgs/x.svg";
import { ReactComponent as undo } from "./svgs/undo.svg";
import { ReactComponent as upload } from "./svgs/upload.svg";
import { ReactComponent as warehousePackages } from "./svgs/warehousePackages.svg";
import { ReactComponent as copy } from "./svgs/copy.svg";
import { ReactComponent as star } from "./svgs/star.svg";
import { ReactComponent as stopwatch } from "./svgs/stopwatch.svg";
import { ReactComponent as trendingUpArrow } from "./svgs/tending-up-arrow.svg";
import { ReactComponent as tool } from "./svgs/tool.svg";
import { ReactComponent as bin } from "./svgs/bin.svg";
import { ReactComponent as wordmark } from "./svgs/wordmark.svg";
import { ReactComponent as wordmarkRockwell } from "./svgs/wordmark-rockwell.svg";
import { ReactComponent as logo } from "./svgs/logo.svg";
import { ReactComponent as hamburger } from "./svgs/hamburger.svg";
import { ReactComponent as lockup } from "./svgs/lockup.svg";
import { ReactComponent as linkedin } from "./svgs/linkedin.svg";
import { ReactComponent as loader } from "./svgs/loader.svg";
import { ReactComponent as facebook } from "./svgs/facebook.svg";
import { ReactComponent as instagram } from "./svgs/instagram.svg";
import { ReactComponent as scribble1 } from "./svgs/scribble-1.svg";
import { ReactComponent as scribble2 } from "./svgs/scribble-2.svg";
import { ReactComponent as scribble3 } from "./svgs/scribble-3.svg";
import { ReactComponent as shield } from "./svgs/shield.svg";
import { ReactComponent as speed } from "./svgs/speed.svg";
import { ReactComponent as speedometer } from "./svgs/speedometer.svg";
import { ReactComponent as stack } from "./svgs/stack.svg";
import { ReactComponent as chevronDown } from "./svgs/chevron-down.svg";
import { ReactComponent as chevronLeft } from "./svgs/chevron-left.svg";
import { ReactComponent as chevronRight } from "./svgs/chevron-right.svg";
import { ReactComponent as circleBlurred } from "./svgs/circle-blurred.svg";
import { ReactComponent as arrowChalk1 } from "./svgs/arrow-chalk-1.svg";
import { ReactComponent as arrowChalk2 } from "./svgs/arrow-chalk-2.svg";
import { ReactComponent as letterA } from "./svgs/letter-a.svg";
import { ReactComponent as letterD } from "./svgs/letter-d.svg";
import { ReactComponent as letterE } from "./svgs/letter-e.svg";
import { ReactComponent as letterFI } from "./svgs/letter-fi.svg";
import { ReactComponent as letterL } from "./svgs/letter-l.svg";
import { ReactComponent as letterM } from "./svgs/letter-m.svg";
import { ReactComponent as letterY } from "./svgs/letter-y.svg";
import { ReactComponent as lift } from "./svgs/lift.svg";
import { ReactComponent as lightningBolt } from "./svgs/lightning-bolt.svg";
import { ReactComponent as link } from "./svgs/link.svg";
import { ReactComponent as bookDesktop } from "./svgs/book-desktop.svg";
import { ReactComponent as bookMobile } from "./svgs/book-mobile.svg";
import { ReactComponent as checkCircle } from "./svgs/check-circle.svg";
import { ReactComponent as check } from "./svgs/check.svg";
import { ReactComponent as checkedBox } from "./svgs/checked-box.svg";
import { ReactComponent as flower } from "./svgs/flower.svg";
import { ReactComponent as clock } from "./svgs/clock.svg";
import { ReactComponent as medal } from "./svgs/medal.svg";
import { ReactComponent as dollarSign } from "./svgs/dollar-sign.svg";
import { ReactComponent as doubleChevronRight } from "./svgs/double-chevron-right.svg";
import { ReactComponent as locationPin } from "./svgs/location-pin.svg";
import { ReactComponent as footerMobileLetters } from "./svgs/footer-mobile-letters.svg";
import { ReactComponent as footprint } from "./svgs/footprint.svg";
import { ReactComponent as atfL } from "./svgs/atf-l.svg";
import { ReactComponent as atfLRotated } from "./svgs/atf-l-rotated.svg";
import { ReactComponent as atfM } from "./svgs/atf-m.svg";
import { ReactComponent as atfMRotated } from "./svgs/atf-m-rotated.svg";
import { ReactComponent as batteryCharging } from "./svgs/battery-charging.svg";
import { ReactComponent as cog } from "./svgs/cog.svg";
import { ReactComponent as cogLight } from "./svgs/cog-light.svg";
import { ReactComponent as move } from "./svgs/move.svg";
import { ReactComponent as otto100 } from "./svgs/otto-100.svg";
import { ReactComponent as otto600 } from "./svgs/otto-600.svg";
import { ReactComponent as otto1200 } from "./svgs/otto-1200.svg";
import { ReactComponent as otto1500 } from "./svgs/otto-1500.svg";
import { ReactComponent as ottoLifter } from "./svgs/otto-lifter.svg";
import { ReactComponent as play } from "./svgs/play.svg";
import { ReactComponent as twitter } from "./svgs/twitter.svg";
import { ReactComponent as youtube } from "./svgs/youtube.svg";

const svgs = Object.freeze({
  amrLifter,
  amrSmall,
  atfL,
  atfLRotated,
  atfM,
  atfMRotated,
  arrowDown,
  arrowLeft,
  arrowRight,
  arrowUpRight,
  lookingGlass,
  minus,
  palopayWordmark,
  phone,
  mail,
  pin,
  playCircle,
  plus,
  radioWaves,
  rockwellOttoLogo,
  clock,
  medal,
  dollarSign,
  doubleChevronRight,
  locationPin,
  hamburger,
  copy,
  bin,
  warningTriangle,
  weight,
  x,
  wordmark,
  wordmarkRockwell,
  star,
  stopwatch,
  trendingUpArrow,
  scribble1,
  scribble2,
  scribble3,
  shield,
  speed,
  speedometer,
  stack,
  logo,
  lockup,
  undo,
  upload,
  warehousePackages,
  facebook,
  instagram,
  chevronDown,
  chevronLeft,
  chevronRight,
  circleBlurred,
  arrowChalk1,
  arrowChalk2,
  letterA,
  letterD,
  letterE,
  letterFI,
  letterL,
  letterM,
  letterY,
  lift,
  lightningBolt,
  link,
  bookDesktop,
  bookMobile,
  checkCircle,
  check,
  checkedBox,
  flower,
  footerMobileLetters,
  footprint,
  linkedin,
  arrowUpCircle,
  download,
  ellipsis,
  settings,
  tool,
  loader,
  batteryCharging,
  cog,
  cogLight,
  move,
  otto100,
  otto600,
  otto1200,
  otto1500,
  ottoLifter,
  play,
  twitter,
  youtube,
});

export type TSvg = keyof typeof svgs;

interface IProps {
  svg: TSvg;
  className?: string;
  style?: React.CSSProperties;
  forwardRef?: React.ForwardedRef<HTMLDivElement>;
}

const SVG = ({ svg, forwardRef, ...props }: IProps) => {
  if (!svgs[svg]) return null;

  const SVGElement = svgs[svg];

  return <SVGElement ref={forwardRef} {...props} aria-hidden />;
};

export default SVG;
